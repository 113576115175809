import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const ChevronIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "up" | "down" | "left" | "right" }) => {
  let rotation = 0;
  if (props.direction == "down") {
    rotation = 90;
  } else if (props.direction == "left") {
    rotation = 180;
  } else if (props.direction == "up") {
    rotation = -90;
  }

  return (
    <StyledSvg viewBox="0 0 16 16" {...omit(props, "direction")}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62439 1.95284C5.52986 1.91298 5.4283 1.89248 5.32572 1.89258C5.17327 1.89277 5.0243 1.9384 4.89791 2.02364C4.77152 2.10888 4.67341 2.22987 4.61611 2.37114C4.55882 2.51242 4.54493 2.66756 4.57624 2.81676C4.6075 2.96573 4.68239 3.10203 4.79137 3.20828L9.58217 7.99909L4.79161 12.7897C4.71838 12.8602 4.6599 12.9445 4.61957 13.0378C4.5791 13.1315 4.55772 13.2322 4.55668 13.3342C4.55564 13.4362 4.57497 13.5374 4.61352 13.6318C4.65208 13.7263 4.70909 13.8121 4.78121 13.8842C4.85334 13.9563 4.93913 14.0133 5.03357 14.0519C5.128 14.0904 5.22918 14.1098 5.33118 14.1087C5.43317 14.1077 5.53394 14.0863 5.62757 14.0458C5.72087 14.0055 5.80522 13.947 5.87572 13.8738L11.2084 8.54114C11.3521 8.39736 11.4328 8.20238 11.4328 7.99909C11.4328 7.79579 11.3521 7.60081 11.2084 7.45702L5.8755 2.12416C5.80407 2.05087 5.71869 1.99262 5.62439 1.95284Z"
        fill={props.color ?? "currentcolor"}
        style={{
          transform: `rotate3d(0, 0, 1, ${rotation}deg)`,
          transformOrigin: "center",
          transitionDuration: "0.125s",
          transitionProperty: "transform",
        }}
      />
    </StyledSvg>
  );
};
